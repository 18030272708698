import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'

import { Phone, MapPin, Mail } from 'react-feather'
import Lottie from 'react-lottie'
import animationData from '../img/contact-animation.json'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  address,
  phone,
  email,
  locations
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <>
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <section className=" pb-24 md:px-24 w-full">
        <div
          class="max-w-screen-xl mt-24 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 pb-16 pt-4 mx-auto  text-gray-900 rounded-lg shadow-lg"
          style={{ backgroundColor: '#f7f7f7' }}
        >
          <div class="flex flex-col justify-between">
            <div>
              <h2 class="text-4xl lg:text-5xl font-bold leading-tight">
                Lets talk about everything!
              </h2>
              <div class="text-gray-700 mt-8">
                Hate forms? Send us an{' '}
                <a href={`mailto:${email}`}>
                  <span class="underline">email</span>
                </a>{' '}
                instead.
              </div>
            </div>
            <div>
              <div className="float-left w-full">
                <Lottie options={defaultOptions} height={250} />
              </div>
            </div>
            <div class="text-center">
              <div className="flex flex-col ">
                {address && (
                  <a
                    className="flex m-2"
                    href={`https://www.google.com/maps/search/${encodeURI(
                      address
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MapPin />
                    <span className="pl-2 hover:text-orange-sherpa">
                      {address}
                    </span>
                  </a>
                )}
                {phone && (
                  <a className="flex m-2" href="https://api.whatsapp.com/send?phone=6282216101222&text=Terima+kasih+telah+menghubungi+team+Sherpa+Indo+Project.+Silakan+ketik+info+%F0%9F%98%8A">
                    <Phone />{' '}
                    <span className="pl-2 hover:text-orange-sherpa">
                      {' '}
                      {phone}{' '}
                    </span>
                  </a>
                )}
                {email && (
                  <a className="flex m-2" href={`mailto:${email}`}>
                    <Mail />
                    <span className="pl-2 hover:text-orange-sherpa">
                      {' '}
                      {email}
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <div>
              <span class="uppercase text-sm text-gray-600 font-bold">
                Service
              </span>
              <select class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg border border-gray-200 focus:outline-none focus:border-orange-sherpa">
                <option>Social Media Management</option>
                <option>Design</option>
                <option>Video & Photography</option>
                <option>Pengembangan Aplikasi</option>
                <option>Konsultan Bisnis</option>
              </select>
            </div>
            <div class="mt-8">
              <span class="uppercase text-sm text-gray-600 font-bold">
                Full Name
              </span>
              <input
                class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg border border-gray-200 focus:outline-none focus:border-orange-sherpa"
                type="text"
                placeholder=""
                name="name"
              />
            </div>
            <div class="mt-8">
              <span class="uppercase text-sm text-gray-600 font-bold">
                Email
              </span>
              <input
                class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg border border-gray-200 focus:outline-none focus:border-orange-sherpa"
                type="email"
                name="email"
              />
            </div>
            <div class="mt-8">
              <span class="uppercase text-sm text-gray-600 font-bold">
                Message
              </span>
              <textarea
                class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg border border-gray-200 focus:outline-none focus:border-orange-sherpa"
                name="message"
              ></textarea>
            </div>
            <div class="mt-8">
              <button
                type="submit"
                class="uppercase text-sm font-bold tracking-wide bg-orange-sherpa text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>

      <GoogleMap locations={locations} />
    </>
  )
}

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 98) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        address
        phone
        email
        locations {
          mapLink
          lat
          lng
        }
      }
    }
  }
`
