import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { MapPin } from 'react-feather'


let mapkey = ''
if (process.env.NETLIFY_MAP_KEY) {
  mapkey = process.env.NETLIFY_MAP_KEY
} else {
  mapkey="AIzaSyDhvJBJ-vJn9lzbhWoEagvTc5VfUzFvzmM"
}

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: -6.942973,
      lng: 107.634915,
    },
    zoom: 14
  }


  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
        bootstrapURLKeys={{ key: mapkey }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={-6.942973} lng={107.634915} text={'Kreyser Avrora'} />
        </GoogleMapReact>
      </div>
    )
  }
}

export default GoogleMap

const Marker = () => {
  return (
    <MapPin></MapPin>
  )
}
